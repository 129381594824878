import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class UtilsService {
  constructor() {}

  getObjFromId(jarray: any[], key: any) {
    for (let i = 0; i < jarray.length; i++) {
      if (jarray[i].id === key) {
        return i;
      }
    }
    return -1;
  }

  findObjWithAttr(array: any[], attr: string, value: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  hexToRgb(hex) {
    if (hex) {
      const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
      if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

      const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
      if (shorthand)
        return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
    }

    return null;
  }
  mixColor(color_1, color_2, weight) {
    // Remove the '#' if it exists.
    if (color_1[0] === "#") {
      color_1 = color_1.slice(1);
    }
    if (color_2[0] === "#") {
      color_2 = color_2.slice(1);
    }

    function d2h(d) {
      return d.toString(16);
    } // convert a decimal value to hex

    function h2d(h) {
      return parseInt(h, 16);
    } // convert a hex value to decimal

    weight = typeof weight !== "undefined" ? weight : 50; // default to 50%

    var color = "#";

    for (var i = 0; i <= 5; i += 2) {
      // Loop through red, green, and blue pairs
      var v1 = h2d(color_1.substr(i, 2)),
        v2 = h2d(color_2.substr(i, 2));
      // Mix the color values based on the weight
      var val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

      // Ensure each hex pair is two characters long
      while (val.length < 2) {
        val = "0" + val;
      }

      color += val;
    }

    return color;
  }
}
