import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../api/api.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { HotelService } from "../hotel/hotel.service";
import { LanguageService } from "../language/language.service";
import { Preferences } from "@capacitor/preferences";
import { AnalyticsService } from "../analytics/analytics.service";
import { ChooseAppPopoverComponent } from "../../components/choose-app-popover/choose-app-popover.component";
import { PopoverController } from "@ionic/angular";
import { HTApplication } from "../../models/ht-application";
const UI_SETTINGS = "appUISettings";

@Injectable({
  providedIn: "root"
})
export class UiSetupService {
  application;
  ui_settings;
  private uiUpdated = new Subject<any>();
  private appUpdated = new Subject<any>();
  private themeUpdated = new Subject<any>();

  constructor(
    private hotelService: HotelService,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private analytics: AnalyticsService,
    private popoverController: PopoverController
  ) {
    this.getStoredApplication().then((app) => {
      if (app) {
        this.application = JSON.parse(app.value);
        this.appUpdated.next({ ...this.application });
      }
    });
    Preferences.get({ key: UI_SETTINGS }).then((old_ui_config) => {
      if (old_ui_config) {
        const old_config = JSON.parse(old_ui_config.value);
        this.ui_settings = old_config;
        console.log("triggered the ui-settings", this.ui_settings);
        this.uiUpdated.next({ ...this.ui_settings });
      }
    });
  }

  getUIUpdateListener() {
    return this.uiUpdated.asObservable();
  }
  getAppUpdateListener() {
    return this.appUpdated.asObservable();
  }
  getThemeUpdateListener() {
    return this.themeUpdated.asObservable();
  }

  updateUISettings(ui_settings_json) {
    this.ui_settings = ui_settings_json;
    this.uiUpdated.next({ ...this.ui_settings });
  }

  async loadUISettings() {
    const saved_ui_settings = await Preferences.get({ key: UI_SETTINGS });
    let reset = false;
    const newKey = (await Preferences.get({ key: "slugApiKey" })).value;
    if (!!newKey) {
      if (this.apiService.getApiKey() !== newKey) {
        reset = true;
        this.apiService.setApiKey(newKey);
      }
    }
    await this.apiService
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.menus.orderForms,pages.children.menus.orderForms"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          // console.log("hotel with pages in UIAppSettings", res["data"]);
          res["data"].pages.forEach((page) => {
            this.hotelService.archivePage(page);
          });
          if (!!this.authService.user) {
            res["data"].pages = this.hotelService.filterVIP(
              res["data"].pages,
              this.authService.booking.room
                ? this.authService.booking.room.id
                : null
            );
          } else {
            res["data"].pages = this.hotelService.filterVIP(
              res["data"].pages,
              null
            );
          }

          return res["data"];
        })
      )
      .subscribe((hotel) => {
        const htApp = hotel;
        // console.log("THIS HOTEL UI SETTINGS", htApp);

        this.hotelService.setHotel(htApp);
        if (saved_ui_settings.value) {
          const ui_settings_json = saved_ui_settings
            ? JSON.parse(saved_ui_settings.value)
            : null;
          this.ui_settings = ui_settings_json;
          this.uiUpdated.next({ ...this.ui_settings });
          if (!!htApp.app_ui_settings) {
            if (
              ui_settings_json === null ||
              ui_settings_json.version < htApp.app_ui_settings.version ||
              reset
            ) {
              Preferences.set({
                key: UI_SETTINGS,
                value: JSON.stringify(htApp.app_ui_settings)
              }).then((_) => {
                this.ui_settings = htApp.app_ui_settings;
                console.log("SAVING NEW JSON", this.ui_settings);

                this.uiUpdated.next({ ...this.ui_settings });
              });
            }
          }
        } else {
          if (!!htApp.app_ui_settings) {
            // this.ui_settings = htApp.app_ui_settings;
            Preferences.set({
              key: UI_SETTINGS,
              value: JSON.stringify(htApp.app_ui_settings)
            }).then((_) => {
              this.ui_settings = htApp.app_ui_settings;

              this.uiUpdated.next({ ...this.ui_settings });
            });
          } else {
            // this.ui_settings = environment.appUISettings;
            this.ui_settings = environment.appUISettings;
            this.uiUpdated.next({ ...this.ui_settings });
          }
        }
        return this.ui_settings;
      });
  }

  setupUI(hotelSettings) {
    this.ui_settings = hotelSettings;
    this.uiUpdated.next({ ...this.ui_settings });
    Preferences.get({ key: UI_SETTINGS }).then((old_ui_config) => {
      if (old_ui_config) {
        let old_config = JSON.parse(old_ui_config.value);
        if (old_config) {
          Preferences.set({
            key: UI_SETTINGS,
            value: JSON.stringify(this.ui_settings)
          });
        } else {
          Preferences.set({
            key: UI_SETTINGS,
            value: JSON.stringify(this.ui_settings)
          });
        }
      } else {
        Preferences.set({
          key: UI_SETTINGS,
          value: JSON.stringify(this.ui_settings)
        });
      }
    });
  }

  getUISettings() {
    return this.ui_settings;
  }

  clearUISettings() {
    this.ui_settings = null;
    this.uiUpdated.next({ ...this.ui_settings });
    return Preferences.set({
      key: UI_SETTINGS,
      value: null
    });
  }

  getStoredApplication() {
    return Preferences.get({ key: "application" });
  }

  getApplication(): Observable<any> {
    return this.apiService
      .get(
        "applications/current",
        {
          // include: 'pages,pages.weekSchedule,pages.reservationForm,pages.space,pages.service'
        },
        null,
        false
      )
      .pipe(
        map((res) => {
          this.languageService.setAvailLangs(res["data"].langs);
          if (res["data"].langs.length === 1) {
            this.translateService.use(res["data"].langs[0]);
            Preferences.set({ key: "lang", value: res["data"].langs[0] });
          }
          this.application = res["data"];
          // this.setApplication(this.application);
          this.appUpdated.next({ ...this.application });
          return res["data"];
        })
      );
  }

  setApplication(application) {
    console.log("Setting application", application);
    return Preferences.set({
      key: "application",
      value: JSON.stringify(application)
    });
  }

  async resetApplication() {
    await Preferences.remove({ key: "application" });
  }

  themeChanged(dark) {
    this.themeUpdated.next(dark);
  }

  async chooseAppWeb(
    htApp: HTApplication,
    redirectURL: string,
    appSlug: string
  ) {
    const popover = await this.popoverController.create({
      component: ChooseAppPopoverComponent,
      cssClass: "choose-app-alert-popover",
      event: null,
      translucent: true,
      componentProps: {
        htApp: htApp,
        redirectURL: redirectURL
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    // console.log("onDidDismiss resolved with role", role);
  }
}
