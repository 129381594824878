import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { FeedbackConfig } from "../../models/feedback-config";
import {
  AnalyticsService,
  EVENTORIGIN,
  EVENTTYPES
} from "../../services/analytics/analytics.service";
import { HotelService } from "../../services/hotel/hotel.service";
import { HTApplication } from "../../models/ht-application";

@Component({
  selector: "choose-app-popover",
  templateUrl: "./choose-app-popover.component.html",
  styleUrls: ["./choose-app-popover.component.scss"]
})
export class ChooseAppPopoverComponent implements OnInit {
  @Input() htApp!: HTApplication;
  @Input() redirectURL!: string;
  @Input() appSlug!: string;
  constructor() {}

  ngOnInit() {}

  openUrl() {
    const link = encodeURI(
      "https://guestapp.hoteltoolbox.gr" + this.redirectURL
    );
    const url =
      this.htApp.qr_config.base_url +
      "?apn=" +
      this.htApp.qr_config.apn +
      "&ibi=" +
      this.htApp.qr_config.ibi +
      "&isi=" +
      this.htApp.qr_config.isi +
      "&link=" +
      link +
      "&ifl=" +
      link;
    window.open(url, "_blank");
  }

  continueOnWeb() {
    window.open("https://guestapp.hoteltoolbox.gr" + this.redirectURL, "_self");
  }
  dismiss(save) {
    // this.popoverCtrl.dismiss({ save: save, comment: this.comment });
  }
}
