import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Page } from "../../models/page";
import { ApiService } from "../api/api.service";
import { Hotel } from "../../models/hotel";
import { AuthenticationService } from "../authentication/authentication.service";
import { Space } from "../../models/space";
import { AnalyticsService } from "../analytics/analytics.service";
import { Weather } from "../../models/weather";
@Injectable({
  providedIn: "root"
})
export class HotelService {
  hotel: Hotel;
  private hotelUpdated = new Subject<any>();
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private analytics: AnalyticsService
  ) {}

  getHotelUpdateListener() {
    return this.hotelUpdated.asObservable();
  }

  getHotel(cache: boolean = true) {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.menus.orderForms,pages.children.menus.orderForms"
        },
        null,
        cache
      )
      .pipe(
        map((res) => {
          // console.log("hotel with pages", res);
          res["data"].pages.forEach((page) => {
            this.archivePage(page);
          });
          if (!!this.authService.user) {
            res["data"].pages = this.filterVIP(
              res["data"].pages,
              this.authService.booking.room
                ? this.authService.booking.room.id
                : null
            );
            res["data"].pages = this.filterDMC(
              res["data"].pages,
              this.authService.booking.dmc ? this.authService.booking.dmc : null
            );
          } else {
            res["data"].pages = this.filterVIP(res["data"].pages, null);
            res["data"].pages = this.filterDMC(res["data"].pages, null);
          }

          return res["data"];
        })
      )
      .subscribe((hotel: Hotel) => {
        return this.setHotel(hotel);
      });
  }

  setHotel(hotel: Hotel) {
    // console.log("SETTING HOTEL", hotel);
    if (hotel) {
      if (!this.hotel) {
        this.hotel = hotel;
        this.analytics.setProperty("hotelId", this.hotel.id);

        this.hotelUpdated.next({ ...this.hotel });
      } else {
        this.hotel = hotel;
        this.analytics.setProperty("hotelId", this.hotel.id);

        this.hotelUpdated.next({ ...this.hotel });
      }
    }
  }
  archivePage(page) {
    if (page.children) {
      page.children.forEach((element) => {
        this.archivePage(element);
      });
    }
  }

  filterVIP(pages, room) {
    if (!room) {
      pages = pages.filter((a) => {
        if (a.room_ids) {
          return a.room_ids.length < 1;
        } else {
          return true;
        }
      });
    } else {
      pages = pages.filter((a) => {
        if (!a.room_ids) {
          return true;
        }
        return a.room_ids.length < 1 || a.room_ids.indexOf(room) !== -1;
      });
    }
    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];
      if (page.reservability_room_ids) {
        if (!room) {
          if (page.reservability_room_ids.length > 0) {
            pages[index].isReservable = false;
          }
        } else {
          if (
            page.reservability_room_ids.length > 0 &&
            page.reservability_room_ids.indexOf(room) === -1
          ) {
            pages[index].isReservable = false;
          }
        }
      }
      page.children = this.filterVIP(page.children, room);
    }
    return pages;
  }

  filterDMC(pages, dmc) {
    if (!dmc) {
      pages = pages.filter((a) => {
        if (a.booking_dmc_whitelist) {
          return a.booking_dmc_whitelist.length < 1;
        } else {
          return true;
        }
      });
    } else {
      pages = pages.filter((a) => {
        if (!a.booking_dmc_whitelist) {
          return true;
        }
        return (
          a.booking_dmc_whitelist.length < 1 ||
          a.booking_dmc_whitelist.indexOf(dmc) !== -1
        );
      });
    }
    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];
      page.children = this.filterDMC(page.children, dmc);
    }
    return pages;
  }
  getHotels(): Observable<Hotel[]> {
    return this.api
      .get(
        "guests/hotels",
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  getWeather(): Observable<Weather> {
    return this.api
      .get(
        `hotels/${this.authService.getHotelId()}/openweathermap/forecast`,
        {},
        null,
        true
      )
      .pipe(
        map((res) => {
          if (res.data) {
            res.data.list.forEach((day) => {
              // day['image'] = this.filterIcon(day);
              day["image"] = this.filterIcon(day);
            });
            const dailyData = res.data.list.reduce((acc, entry) => {
              // Extract the date (e.g., "2025-02-12") from dt_txt
              const date = entry.dt_txt.split(" ")[0];
              // Initialize the array for the date if it doesn't exist
              if (!acc[date]) {
                acc[date] = [];
              }
              // Add the current entry to the group for that date
              acc[date].push(entry);
              return acc;
            }, {});

            // Now create an array that holds one object per day with the min and max temp
            const dailyTemps = Object.keys(dailyData).map((date) => {
              const entries = dailyData[date];
              // Get an array of all the min temperatures and max temperatures for the day
              const tempsMin = entries.map((e) => e.main.temp_min);
              const tempsMax = entries.map((e) => e.main.temp_max);
              const representative = this.getRepresentativeEntry(entries, 12);
              // const icon = representative.weather[0].icon;
              const icon = this.filterIcon(representative);

              console.log(icon);
              return {
                date,
                minTemp: Math.min(...tempsMin),
                maxTemp: Math.max(...tempsMax),
                icon: icon
              };
            });
            res.data.dailyTemps = dailyTemps;

            const now = Date.now() / 1000;

            const currentWeather = res.data.list.reduce((closest, item) => {
              return Math.abs(item.dt - now) < Math.abs(closest.dt - now)
                ? item
                : closest;
            }, res.data.list[0]);
            res.data.currentWeather = currentWeather;
          }
          return res.data;
        })
      );
  }

  getRepresentativeEntry(entries, targetHour = 12) {
    return entries.reduce((prev, curr) => {
      // Get the hour (as number) from the dt_txt property
      const currHour = parseInt(curr.dt_txt.split(" ")[1].split(":")[0], 10);
      const prevHour = parseInt(prev.dt_txt.split(" ")[1].split(":")[0], 10);
      // Compare differences from the target hour (12:00)
      return Math.abs(currHour - targetHour) < Math.abs(prevHour - targetHour)
        ? curr
        : prev;
    }, entries[0]);
  }

  getPages(): Observable<Page[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus,pages.menus.orderForms,pages.children.menus.orderForms"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"].pages["data"];
        })
      );
  }

  getSpaces(): Observable<Space[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/spaces",
        {
          include: "page,page.weekSchedule,page.reservationForm"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  findPageById(id): Page {
    var target = this.getNodeById(id, this.hotel.pages);
    // target.children = children;
    return target;
  }

  getNodeById(id, node) {
    var reduce = [].reduce;
    function runner(result, node) {
      if (result || !node) return result;
      return (
        (node.id === id && node) || //is this the proper node?
        runner(null, node.children) || //process this nodes children
        reduce.call(Object(node), runner, result)
      ); //maybe this is some ArrayLike Structure
    }
    return runner(null, node);
  }

  filterIcon(data) {
    let code = data.weather[0].icon.substring(0, 2);
    if (code === "01" || code === "50" || code === "02") {
      return "menu_icon-weather-" + data.weather[0].icon;
    } else {
      return "menu_icon-weather-" + code;
    }
  }
}
